<template>
<div>
  <component :is="'b-card'">
    <!-- Form: General Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: title -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Name')" label-for="name">
              <validation-provider #default="{ errors }" rules="required" name="name">
                <b-form-input id="name" v-model="itemData.name" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Order Number')" label-for="number">
              <validation-provider #default="{ errors }" name="number" rules="required">
                <b-form-input id="number" v-model="itemData.number" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Active -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="itemData.isActive"
                  class="custom-control-primary">
                  {{ $t('Active')}}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <save-button 
              :onClickSaveAndClose="save.bind(this, true)"
              :onClickSave="save.bind(this, false)"
              />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import { getDataVersion } from '@/store/ancestry-data/utils'

// Alert fade
import { heightFade } from '@core/directives/animations'
// Save Button
import SaveButton from '@/components/SaveButton.vue'

// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,

      itemData: {
        id: 0,
        name: null,
        number: null,
        isActive: true,
        dataVersion: getDataVersion(this.$store),
      },
    }
  },

  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('presetAncientPeriodManage/fetch', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.itemData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    save(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.itemData.dataVersion !== getDataVersion(this.$store)) {
            AlertService.warning(this, 'Data version does not match selected version');
            return;
          }

          this.$store.dispatch('presetAncientPeriodManage/save', this.itemData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.itemData = response.result;
                  this.$router.push({ name: 'manage-preset-ancient-period-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },
  },
  
  created() {
    this.fetchItem();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
